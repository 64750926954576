<template>
  <v-container fluid>
    <Breadcrumb :data="breadcrumb" />
    <section class="my-5">
      <v-row>
        <v-col cols="12" class="relative" order="1">
          <!-- <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3455.1637646632344!2d31.431815014886908!3d30.003453727463242!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14583cc7ded677ad%3A0xda893b921fd94c38!2sCement%20Home!5e0!3m2!1sen!2seg!4v1643664975681!5m2!1sen!2seg"
            style="width: 100%; border: 0" height="472" allowfullscreen="" loading="lazy"></iframe> -->
        </v-col>

        <v-col cols="12" lg="4" sm="12" order="4" order-lg="4">
          <v-col class="d-none d-lg-block pa-0" cols="12">
            <ProductFilter :prices="prices" :isDialog="false" :activeParam="activeParam" :isLoading="isLoading"
              :isFilter="isFilter" :all_producers="producers" :products="products" :products_types="products_types"
              :governorates="governorates" :items="items" :backValueMethod="filterBackMethod" />
          </v-col>
          <!-- Saved Search -->
          <v-col cols="12" lg="12" sm="12" class="d-none d-lg-block pa-0">
            <SavedFilterResults :getData="(param) => getData(param)" :isDialog="false" :isLoading="isLoading"
              :closeDialog="() => savedResultsDialog = false" :all_producers="producers" :products="products"
              :products_types="products_types" :governorates="governorates" :items="items" />
          </v-col>
          <v-row>
            <!-- ADS -->
            <!-- <v-col cols="12" lg="12" md="12" sm="6" class="px-0 cursor_pointer">
              <ADImg :ad="firstAd" :maxHeight="135" :maxWidth="'auto'" :contain="true" :aspectRatio="1280 / 400"
                classes="mt-3 rounded-lg" />
            </v-col>
            <v-col cols="12" lg="12" md="12" sm="6" class="px-0 cursor_pointer">
              <ADImg :ad="secondAd" :maxHeight="135" :maxWidth="'auto'" :contain="true" :aspectRatio="1280 / 400"
                classes="mt-3 rounded-lg" />
            </v-col>
            <v-col cols="12" lg="12" md="12" sm="6" class="px-0 cursor_pointer">
              <ADImg :ad="thirdAd" :maxHeight="135" :maxWidth="'auto'" :contain="true" :aspectRatio="1280 / 400"
                classes="mt-3 rounded-lg" />
            </v-col> -->
            <!-- Move Around Services -->
            <v-col cols="12" lg="12" sm="12">
              <MoveAroundServices :isLoading="false" :data="services" title="Move Around Services" />
            </v-col>

          </v-row>
        </v-col>
        <!-- --------------------------------------------------------------------------------------------- -->
        <!-- Materials prices Tables -->
        <v-col cols="12" lg="8" sm="12" order="3" order-lg="5" class="my-15" v-if="isLoading">
          <v-progress-circular :size="70" :width="5" style="margin: auto" class="d-block" color="primary" indeterminate />
        </v-col>
        <v-col cols="12" lg="8" sm="12" order="3" order-lg="5" v-if="!isLoading" id="prices-section">
          <v-col cols="12" class="d-lg-none d-flex justify-end">
            <v-btn color="success" class="mx-2" outlined @click="savedResultsDialog = true"
              v-if="($store.state.savedSearches.length > 0)">
              <v-icon left>mdi-filter-variant</v-icon>
              {{ $t("saved results") }}
            </v-btn>
            <v-btn color="primary" outlined @click="filterDialog = !filterDialog">
              <v-icon left>mdi-filter</v-icon>
              {{ $t("material filter") }}
            </v-btn>
          </v-col>

          <v-col v-if="!Object.keys(groupPrices).length">
            <v-alert type="error" outlined>
              {{ $t('no prices found for this filter') }}
            </v-alert>
          </v-col>

          <MaterialPriceTable v-for="(groupKey, index) in Object.keys(groupPrices)" :key="index" :isLoading="isLoading"
            :data="groupPrices[groupKey]" :showGovernorate="true" showType="true"
            :title="$route.params.meta_name.replaceAll('-', ' ') + ' - ' + groupKey.replaceAll('-', ' ')"
            :hideViewMore="true" />
          <!-- Ads -->
          <v-col cols="12" sm="12">
            <ADImg :ad="fourthAd" :maxHeight="600" :maxWidth="'100vw'" :contain="true" :aspectRatio="640 / 340"
              classes="mt-3 rounded-lg" />
          </v-col>
        </v-col>

        <!-- End Materials prices Tables -->
      </v-row>

      <v-overlay :opacity="0.9" :absolute="true" :value="(filterDialog || savedResultsDialog)"
        @click="() => { filterDialog = false; savedResultsDialog = false }" />
    </section>


    <v-dialog v-model="filterDialog" overlay-opacity="0" fullscreen transition="dialog-bottom-transition">
      <div class="pa-16 position-relative transparent" @click.self="filterDialog = false">
        <div class="pa-5 position-relative ">
          <ProductFilter :prices="prices" :isDialog="true" :closeDialog="() => filterDialog = false"
            :activeParam="activeParam" :isLoading="isLoading" :isFilter="isFilter" :all_producers="producers"
            :products="products" :products_types="products_types" :governorates="governorates" :items="items"
            :backValueMethod="filterBackMethod" />
        </div>
      </div>
    </v-dialog>

    <v-dialog v-model="savedResultsDialog" overlay-opacity="0" fullscreen transition="dialog-bottom-transition">
      <div class=" position-relative transparent" @click.self="savedResultsDialog = false">
        <div class=" position-relative " style="position: relative;height: 100vh;overflow: hidden !important; "
          @click.self="savedResultsDialog = false">
          <SavedFilterResults :getData="(param) => getData(param)" :isDialog="true" :isLoading="isLoading"
            :closeDialog="() => savedResultsDialog = false" :all_producers="producers" :products="products"
            :products_types="products_types" :governorates="governorates" :items="items" />
        </div>
      </div>
    </v-dialog>

  </v-container>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
import MaterialPriceTable from "@/components/MaterialPriceTable.vue";
import MoveAroundServices from "@/components/MoveAroundServices.vue";
import ProductFilter from "@/components/ProductFilter.vue";
import SavedFilterResults from "@/components/SavedFilterResults.vue";
import ADImg from "@/components/ADImg.vue";
export default {
  name: "MaterialPrices",
  components: {
    Breadcrumb,
    MaterialPriceTable,
    MoveAroundServices,
    ProductFilter,
    SavedFilterResults,
    ADImg
  },
  computed: {
    groupPrices() {
      return this.$global.GroupBy(this.prices, 'product_type_name')
    }
  },
  mounted() {
    this.filterByProduct = Number(this.$route.params.product_id)
    this.$global.ADD_META(this.$i18n.t('material prices'), 'title');
    this.$global.ADD_META([this.$i18n.t('prices') + ' ' + this.$route.params.meta_name, this.$route.params.meta_name + ' ' + this.$i18n.t('prices')], 'keywords');
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    this.from_date = this.$global.GetCurrentDate();
    this.to_date = this.$global.GetCurrentDate();
    // this.getData();
    this.isFilter = false;
    this.checkQueryParam();
  },
  watch: {
    $route() {
      this.filterByProduct = Number(this.$route.params.product_id)
      this.filterByProducer = null;
      this.filterByProductType = null;
      this.filterByItem = null;
      this.filterByGovernorate = null;
      this.from_date = null;
      this.to_date = null;
      this.word = null;
      this.isFilter = true;
      this.$global.DELETE_META();
      this.$global.ADD_META(this.$i18n.t('material prices'), 'title');
      this.$global.ADD_META([this.$i18n.t('prices') + ' ' + this.$route.params.meta_name, this.$route.params.meta_name + ' ' + this.$i18n.t('prices')], 'keywords');
      // this.getData();
      this.isFilter = false;
      this.checkQueryParam();
    },
    filterByProduct() {
      this.filterByProductType = 0;
      this.filterByProducer = null;
      this.filterByItem = null;
      this.filterByWord = null;
      this.filterByGovernorate = null;
    },
  },
  data: () => ({
    breadcrumb: [],
    isFilter: false,
    filterDialog: false,
    savedResultsDialog: false,
    from_date: null,
    to_date: null,
    services: [
      {
        id: 1,
        country: 'KSA',
        image: 'cement-ksa.svg',
      },
      {
        id: 2,
        country: 'UEA',
        image: 'cement-uea.svg',
      },
      {
        id: 3,
        country: 'Oman',
        image: 'cement-oman.svg',
      },
      {
        id: 4,
        country: 'Qatar',
        image: 'cement-qatar.svg',
      },
      {
        id: 5,
        country: 'Kuwait',
        image: 'cement-uea.svg',
      }

    ],
    isLoading: false,
    firstAd: {},
    secondAd: {},
    thirdAd: {},
    fourthAd: {},
    page: null,
    rowsNumber: null,
    pagination: {},
    items: [],
    prices: [],
    producers: [],
    products: [],
    products_types: [],
    governorates: [],
    filtered_governorates: [],
    filterByWord: null,
    filterByProduct: null,
    filterByProducer: null,
    filterByGovernorate: null,
    filterByProductType: 0,
    filterByItem: null,
    activeParam: null
  }),

  methods: {
    checkQueryParam() {
      console.log('router.currentRoute.query', this.$router.currentRoute.query);
      if (Object.keys(this.$router.currentRoute.query).length > 0) {
        let params = this.$router.currentRoute.query
        this.filterByProducer = +params.producer_id || null;
        this.filterByProductType = +params.product_type_id || null;
        this.filterByItem = +params.item_id || null;
        this.filterByGovernorate = +params.gov_id || null;
        this.from_date = params.from_date || null;
        this.to_date = params.to_date || null;
        this.filterByWord = params.word || null;
        this.isFilter = true;
        this.$vuetify.goTo(`#prices-section`)
        this.getData();
      } else {
        this.getData();
      }
    },
    getData(param = null) {
      this.isLoading = true;
      this.breadcrumb = [
        {
          text: this.$i18n.t("cement egypt"),
          disabled: false,
          to: "/",
        },
        {
          text: this.$route.params.meta_name.replaceAll('-', ' '),
          disabled: false,
          to: `/materials/${this.$route.params.product_id}/${this.$route.params.meta_name}`,
        },
      ];
      this.activeParam = param ? param : `?product_id=${this.$route.params.product_id || ''}&producer_id=${this.filterByProducer || ''}&product_type_id=${this.filterByProductType || ''}&item_id=${this.filterByItem || ''}&gov_id=${this.filterByGovernorate || 1}&from_date=${this.from_date || ''}&to_date=${this.to_date || ''}&word=${this.filterByWord || ''}`
      //this.prices = [];
      this.$api.GET_METHOD(`price${this.activeParam}`).then((response) => {
        this.isLoading = false
        if (response.check) {
          this.filterDialog = false;
          this.savedResultsDialog = false;
          this.firstAd = this.$global.FilterArrayOfObject(response.data.prices_ads, 'ads_code', '04-001')[0];
          this.secondAd = this.$global.FilterArrayOfObject(response.data.prices_ads, 'ads_code', '04-002')[0];
          this.thirdAd = this.$global.FilterArrayOfObject(response.data.prices_ads, 'ads_code', '04-003')[0];
          this.fourthAd = this.$global.FilterArrayOfObject(response.data.prices_ads, 'ads_code', '04-004')[0];

          this.products = response.data.products || [];
          this.items = response.data.items || [];
          this.prices = response.data.prices || [];
          this.producers = response.data.producers || [];
          this.governorates = response.data.governorates || [];
          this.filtered_governorates = response.data.governorates || [];
          response.data.products_types.unshift({
            id: 0,
            product_id: Number(this.$route.params.product_id),
            name: this.$i18n.t('all'),
          })
          this.products_types = response.data.products_types || [];
          if (response.data.prices.length > 0 && this.isFilter) {
            this.saveSearch()
          }

        } else {
          this.news = [];
          this.slider_data = [];
        }
      })
    },

    filterBackMethod(search) {
      this.$router.push(`/materials/${search.product_name.split(/\s+/).join('-')}/${search.product_id}?producer_id=${search.producer_id || ''}&product_type_id=${search.product_type_id || ''}&item_id=${search.item_id || ''}&gov_id=${search.gov_id || ''}&from_date=${search.from_date || ''}&to_date=${search.to_date || ''}&turn_number=${search.turn_number || 1}`)
    },
    saveSearch() {
      let search_text = '';
      if (this.$route.params.product_id) {
        search_text = search_text + this.$global.FilterArrayOfObjectElement(this.products, 'id', +this.$route.params.product_id, 'name');
      }
      if (this.filterByProducer) {
        search_text = search_text + ' - ' + this.$global.FilterArrayOfObjectElement(this.producers, 'id', +this.filterByProducer, 'name');
      }
      if (this.filterByProductType) {
        search_text = search_text + ' - ' + this.$global.FilterArrayOfObjectElement(this.products_types, 'id', +this.filterByProductType, 'name');
      }
      if (this.filterByItem) {
        search_text = search_text + ' - ' + this.$global.FilterArrayOfObjectElement(this.items, 'id', +this.filterByItem, 'name');
      }
      if (this.gov_id) {
        search_text = search_text + ' - ' + this.$global.FilterArrayOfObjectElement(this.governorates, 'id', +this.gov_id, 'name');
      }
      if (this.from_date) {
        search_text = search_text + ' - ' + this.from_date;
      }
      if (this.to_date) {
        search_text = search_text + ' - ' + this.to_date;
      }
      const searchParam = {
        product_id: +this.$route.params.product_id,
        product_name: this.$route.params.meta_name,
        producer_id: this.filterByProducer,
        product_type_id: this.filterByProductType,
        item_id: this.filterByItem,
        gov_id: this.filterByGovernorate,
        from_date: this.from_date,
        to_date: this.to_date,
        word: this.filterByWord,
        search_text: search_text,
        param: `?product_id=${this.$route.params.product_id || ''}&producer_id=${this.filterByProducer || ''}&product_type_id=${this.filterByProductType || ''}&item_id=${this.filterByItem || ''}&gov_id=${this.filterByGovernorate || ''}&from_date=${this.from_date || ''}&to_date=${this.to_date || ''}&word=${this.filterByWord || ''}`
      }

      this.$store.dispatch("addSearch", searchParam).then();
    },
  },
};
</script>

<style lang="scss" scoped></style>
